/* stylelint-disable at-rule-no-unknown */

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

output {
  display: inline-block;
}

summary {
  display: list-item; // Add the correct display in all browsers
}

template {
  display: none; // Add the correct display in IE
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: @yellow-1;
}

::selection {
  color: @text-color-inverse;
  background: @text-selection-bg;
}

// Utility classes
.clearfix {
  .clearfix();
}
